import React, { useCallback } from "react";
import { ImageBackground, Platform } from "react-native";
import { Box } from "../components/generic/Box";
import { Image } from "../components/generic/Image";
import { Text } from "../components/generic/Text";
import Animated, { FadeInDown, FadeOutDown } from "react-native-reanimated";
import { ShakeAnimation } from "../components/generic/ShakeAnimation";
import { ChunkButton } from "../components/game/ChunkButton";
import { Container } from "../components/Container";
import { FloatingSettings } from "../components/settings/FloatingSettings";
import { isWeb } from "../util/platform";
import { useKeyboardEvents } from "../hooks/useKeyboardEvents";
import { DownloadIosButton } from "../components/DownloadIosButton";
import { BannerAdvert } from "../components/ads/BannerAdvert";
import { GameButton } from "../components/game/GameButton";
import { COLORS } from "../constants/colors";

interface ILoginScreenProps {
  onStart: () => void;
  date?: string;
  onLongPress?: () => void;
  onSettingsOpen?: () => void;
  isLoading: boolean;
}

const AnimatedImageBackground =
  Animated.createAnimatedComponent(ImageBackground);

export const LoginScreen = ({ onStart, onSettingsOpen }: ILoginScreenProps) => {
  const onKeyboardEvent = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onStart();
        return;
      }
    },
    [onStart]
  );

  useKeyboardEvents(onKeyboardEvent);

  return (
    <>
      <Box flex={1} justifyContent="center" alignItems="center" w="100%">
        <Box
          flex={isWeb ? undefined : 1}
          w="100%"
          justifyContent="center"
          alignItems="center"
        >
          {isWeb ? (
            <Box justifyContent="center" alignItems="center" mb={80}>
              <Image
                showSpinner={false}
                isLoadingOverlay={false}
                w={200}
                h={100}
                resizeMode="contain"
                source={require("../../assets/21-logo.png")}
              />
            </Box>
          ) : null}

          <Container>
            <Box
              h={"100%"}
              py={isWeb ? 30 : 20}
              w={"100%"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box p={4} center={true} py={0} pb={0}></Box>
              {!isWeb ? (
                <Box justifyContent="center" alignItems="center">
                  <Image
                    showSpinner={false}
                    isLoadingOverlay={false}
                    fadeDuration={200}
                    w={250}
                    h={100}
                    resizeMode="contain"
                    source={require("../../assets/21-logo.png")}
                  />
                </Box>
              ) : null}
              <Box
                px={isWeb ? "15%" : "20%"}
                justifyContent="center"
                alignItems="center"
                w="100%"
              >
                <Text
                  fontWeight="bold"
                  color="white"
                  align="center"
                  mb={5}
                  size={isWeb ? 20 : 18}
                >
                  Find the hidden words before time runs out!
                </Text>

                <Text
                  color="rgba(255,255,255,.8)"
                  align="center"
                  size={16}
                  mt={5}
                >
                  Good luck!
                </Text>
              </Box>
              <Box
                w="100%"
                exiting={FadeOutDown.duration(300)}
                flexDirection="row"
                justifyContent="center"
              >
                {Platform.OS !== "web" ? (
                  <Box
                    entering={FadeInDown.delay(100).duration(2000)}
                    w="100%"
                    h={"100%"}
                    position="absolute"
                  >
                    <AnimatedImageBackground
                      style={{
                        width: "100%",
                        height: "240%",
                        bottom: "-130%",
                        opacity: 0.6,
                        position: "absolute",
                      }}
                      resizeMode="contain"
                      source={require("../../assets/button-arrows-3.png")}
                    />
                  </Box>
                ) : null}
                <ShakeAnimation
                  amount={3}
                  style={isWeb ? { marginTop: 50 } : undefined}
                >
                  <GameButton
                    color={COLORS.primary3}
                    label="Play"
                    onPress={onStart}
                    br={800}
                    px={80}
                    textSize={18}
                  />
                  {/* <ChunkButton label="Play" onPress={onStart} /> */}
                </ShakeAnimation>
              </Box>
              <Box p={4} center={true} py={0} pb={0}></Box>
            </Box>
          </Container>
          <DownloadIosButton />
        </Box>
      </Box>
      <FloatingSettings
        onPress={() => {
          onSettingsOpen();
          // setIsSettingsOpen(true);
        }}
      />
    </>
  );
};
